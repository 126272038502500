import { SET_LIABILITY_SUMMARY, SET_LIABILITY_TYPES, SET_LIABILITY_TYPE_SUMMARY } from '../constants/actionTypes'

const initialState = {
  liabilitySummary: [],
  liabilityTypes: [],
  liabilityTypeSummary: []
}

const liabilityInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIABILITY_SUMMARY:
      return Object.assign({}, state, {
        liabilitySummary: action.liabilitySummary
      })
    case SET_LIABILITY_TYPES:
      return Object.assign({}, state, {
        liabilityTypes: action.liabilityTypes
      })
    case SET_LIABILITY_TYPE_SUMMARY:
      return Object.assign({}, state, {
        liabilityTypeSummary: action.liabilityTypeSummary
      })
    default:
      return state
  }
}

export default liabilityInfo