import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { initAssetSummary } from '../../../actions/assets'
import { Auth, API, Logger } from "aws-amplify";


const logger = new Logger('AssetDeleteAction');

function mapStateToProps (state) {
  return {
    assetSummary: state.assetInfo.assetSummary,
    currencyInfo: state.currencyInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initAssetSummary: currencyInfo => dispatch(initAssetSummary(currencyInfo))
  }
}

class AssetDeleteAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: ''
    };
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  async handleDeleteConfirmed(event) {
    event.preventDefault();

    const ridx = this.props.iAssetSummaryRow;
    const name = this.props.assetSummary[ridx][0];
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        'assetName': name
      }
    };
    API.del("AssetInfoGateway", "/asset_info", init)
    .then(() => this.onSuccess(this.state.name))
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      error: ''
    })
  }

  onSuccess(asset) {
    logger.info('Asset Info deleted for ', asset);
    this.setState({ error: '', show: false });
    this.props.initAssetSummary(this.props.currencyInfo);
  }

  onFailure(err) {
    logger.info('Asset Info delete failed: ', err);
    this.setState({ error: err.message || err });
  }

  render() {
    const ridx = this.props.iAssetSummaryRow;
    const name = this.props.assetSummary[ridx][0];
    const type = this.props.assetSummary[ridx][1];
    return (
      <React.Fragment>
        <Button variant="danger" size="sm" onClick={() => this.setState({ show: true })}><Trash /></Button>{' '}
        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Asset Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant='warning'>Are you sure you want to delete the asset <b>{name} ({type})</b>?</Alert>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ show: false })}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={this.handleDeleteConfirmed}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDeleteAction);

