import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

import { initLiabilitySummary } from '../../../actions/liabilities'
import { Auth, API, Logger } from "aws-amplify";


const logger = new Logger('LiabilityUpdateAction');

function mapStateToProps (state) {
  return {
    liabilitySummary: state.liabilityInfo.liabilitySummary,
    currencyInfo: state.currencyInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initLiabilitySummary: currencyInfo => dispatch(initLiabilitySummary(currencyInfo))
  }
}

class LiabilityUpdateAction extends Component {
  constructor(props) {
    super(props);

    const ridx = this.props.iLiabilitySummaryRow;
    const type = this.props.liabilitySummary[ridx][1]
    const name = this.props.liabilitySummary[ridx][0]
    const currency = this.props.liabilitySummary[ridx][2]
    const balance = this.props.liabilitySummary[ridx][3]
    const growthRate = this.props.liabilitySummary[ridx][4]

    this.state = {
      show: false,
      type: type,
      name: name,
      currency: currency,
      balance: balance,
      growthRate: growthRate,
      error: ''
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        'liabilityName': this.state.name,
        'currentBalance': this.state.balance,
        'growthRate': this.state.growthRate
      }
    };
    API.put("LiabilityInfoGateway", "/liability_info", init)
    .then(() => this.onSuccess(this.state.name))
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      error: ''
    })
  }

  onSuccess(liability) {
    logger.info('Liability Info updated for ', liability);
    this.setState({ error: '', show: false });
    this.props.initLiabilitySummary(this.props.currencyInfo);
  }

  onFailure(err) {
    logger.info('Liability Info update failed: ', err);
    this.setState({ error: err.message || err });
  }

  render() {
    return (
      <React.Fragment>
        <Button variant="primary" size="sm" onClick={() => this.setState({ show: true })}><PencilSquare /></Button>

        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Update Liability
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="typeList">
                  <Form.Label>Liability Type</Form.Label>
                  <Form.Control as="input" name="type" type="text" placeholder={this.state.type} disabled />
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>Liability Name</Form.Label>
                  <Form.Control as="input" name="name" type="text" placeholder={this.state.name} disabled />
                </Form.Group>
                <Form.Group controlId="currencyList">
                  <Form.Label>Liability Currency</Form.Label>
                  <Form.Control as="input" name="currency" type="text" placeholder={this.state.currency} disabled />
                </Form.Group>
                <Form.Group controlId="balance">
                  <Form.Label>New Balance</Form.Label>
                  <Form.Control as="input" name="balance" type="number" onChange={this.handleFormChange} placeholder={this.state.balance} />
                </Form.Group>
                <Form.Group controlId="growthRate">
                  <Form.Label>New Growth Rate</Form.Label>
                  <Form.Control as="input" name="growthRate" type="number" onChange={this.handleFormChange} min="0" max="100" step="0.01" placeholder={this.state.growthRate} />
                </Form.Group>
              </Form>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ show: false })}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={this.handleFormSubmit}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiabilityUpdateAction);
