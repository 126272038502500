import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { initLiabilitySummary } from '../../../actions/liabilities'
import { Auth, API, Logger } from "aws-amplify";


const logger = new Logger('LiabilityDeleteAction');

function mapStateToProps (state) {
  return {
    liabilitySummary: state.liabilityInfo.liabilitySummary,
    currencyInfo: state.currencyInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initLiabilitySummary: currencyInfo => dispatch(initLiabilitySummary(currencyInfo))
  }
}

class LiabilityDeleteAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: ''
    };
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  async handleDeleteConfirmed(event) {
    event.preventDefault();

    const ridx = this.props.iLiabilitySummaryRow;
    const name = this.props.liabilitySummary[ridx][0];
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        'liabilityName': name
      }
    };
    API.del("LiabilityInfoGateway", "/liability_info", init)
    .then(() => this.onSuccess(this.state.name))
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      error: ''
    })
  }

  onSuccess(liability) {
    logger.info('Liability Info deleted for ', liability);
    this.setState({ error: '', show: false });
    this.props.initLiabilitySummary(this.props.currencyInfo);
  }

  onFailure(err) {
    logger.info('Liability Info delete failed: ', err);
    this.setState({ error: err.message || err });
  }

  render() {
    const ridx = this.props.iLiabilitySummaryRow;
    const name = this.props.liabilitySummary[ridx][0];
    const type = this.props.liabilitySummary[ridx][1];
    return (
      <React.Fragment>
        <Button variant="danger" size="sm" onClick={() => this.setState({ show: true })}><Trash /></Button>{' '}
        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Liability Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant='warning'>Are you sure you want to delete the liability <b>{name} ({type})</b>?</Alert>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ show: false })}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={this.handleDeleteConfirmed}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiabilityDeleteAction);

