import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';


function mapStateToProps (state) {
  return {
    assetTypeSummary: state.assetInfo.assetTypeSummary,
    currencyInfo: state.currencyInfo
  }
}

class AssetTotalHighlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  render() {
    let totalValue = this.props.assetTypeSummary.reduce( ( accumulator, r ) => accumulator + parseFloat(r[1]), 0 ).toFixed(2)
    return (
        <Alert variant='info'>
          {'Total Assets: ' + formatCurrencyByLocale(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, totalValue)}
        </Alert>
    );
  }
}

export default connect(mapStateToProps)(AssetTotalHighlight);
