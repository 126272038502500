import { SET_ASSET_SUMMARY, SET_ASSET_TYPES, SET_ASSET_TYPE_SUMMARY } from '../constants/actionTypes'
import { Auth, API } from "aws-amplify"
import moment from 'moment'

function computeEoyBalance(currentBalance, growthRate) {
    const msInADay = 1000 * 60 * 60 * 24
    const today = new Date();
    const eoy = new Date(new Date().getFullYear(), 11, 31);
    const nDaysLeftInYear = Math.ceil(Math.abs(eoy - today) / msInADay)
    const eoyBalance = currentBalance*(1 + (growthRate*nDaysLeftInYear)/(100*365))
    return eoyBalance
}

function toAggCurrency(value, originalCurrency, aggregationCurrency, currencyRates) {
    return parseFloat(value)* parseFloat(currencyRates[aggregationCurrency])/parseFloat(currencyRates[originalCurrency])
}

export function initAssetSummary(currencyInfo) {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    var assetSummary = await API.get("AssetInfoGateway", "/asset_info", init);

    assetSummary = assetSummary.sort(function(a,b) {
        if(a[1] < b[1]) { return -1; }
        if(a[1] > b[1]) { return 1; }

        if(a[0] < b[0]) { return -1; }
        if(a[0] > b[0]) { return 1; }

        return 0;
     });

    for (var iRow = 0; iRow < assetSummary.length; iRow++) {
      var balance = assetSummary[iRow][assetSummary[iRow].length - 3]
      var growthRate = assetSummary[iRow][assetSummary[iRow].length - 2]

      assetSummary[iRow].push(computeEoyBalance(balance, growthRate))
      // Update time format for last update string
      assetSummary[iRow][5] = moment(assetSummary[iRow][5]).format("MMM DD, YYYY hh:mm A UTC");
    }

    dispatch(setAssetSummary(assetSummary));
    dispatch(updateAssetTypeSummary(assetSummary, currencyInfo));
  }
}

export function initAssetTypes() {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    const response = await API.get("AssetTypeGateway", "/asset_types", init);
    const assetTypes = [...response.sort()]

    dispatch(setAssetTypes(assetTypes));
  }
}

export function updateAssetTypeSummary(assetSummary, currencyInfo) {
  return async dispatch => {
    let assetTypeSummaryMap = new Map();
    for (var iRow = 0; iRow < assetSummary.length; iRow++) {
      var type = assetSummary[iRow][1]
      var currency = assetSummary[iRow][2]
      var balance = assetSummary[iRow][assetSummary[iRow].length - 4]
      assetTypeSummaryMap.set(type, (
        (parseFloat(assetTypeSummaryMap.get(type)) || 0)
          + toAggCurrency(balance, currency, currencyInfo.aggregationCurrency, currencyInfo.currencyRates)
      ))
    }
    const assetTypeSummary = Array.from(assetTypeSummaryMap).sort(function(a,b) { return b[1] - a[1]; });
    dispatch(setAssetTypeSummary(assetTypeSummary))
  }
}

export const setAssetSummary = assetSummary => ({
  type: SET_ASSET_SUMMARY,
  assetSummary
})

export const setAssetTypes = assetTypes => ({
  type: SET_ASSET_TYPES,
  assetTypes
})

export const setAssetTypeSummary = assetTypeSummary => ({
  type: SET_ASSET_TYPE_SUMMARY,
  assetTypeSummary
})