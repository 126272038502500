export const DATA_COLORS = [
  "#8874a3",
  "#ee4035",
  "#f37736",
  "#f0e790",
  "#7bc043",
  "#0392cf",
  "#35a79c",
  "#851e3e",
  "#c68642"
]
