export const Timeframes = Object.freeze({
  ALL_TIME: "all_time",
  LAST_10_YEARS: "last_10_years",
  LAST_5_YEARS: "last_5_years",
  LAST_2_YEARS: "last_2_years",
  LAST_1_YEAR: "last_1_year",
  YEAR_TO_DATE: "year_to_date",
})


