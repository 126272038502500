import React, { Component } from 'react';
import { Table, OverlayTrigger, Tooltip  } from 'react-bootstrap'
import { connect } from 'react-redux';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';


function mapStateToProps (state) {
  return {
    netWorthSummary: state.netWorthInfo.netWorthSummary,
    currencyInfo: state.currencyInfo
  }
}

class NetWorthTimeSeriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  render() {
    const renderTooltip = (text) => <Tooltip id="tooltip">Updated on {text}</Tooltip>;

    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {
              [
                'Month',
                'Assets',
                'Liabilities',
                'Net Worth'
              ].map(c => <th key={c}>{c}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            this.props.netWorthSummary.slice(0).reverse().map(r =>
              <OverlayTrigger placement="top" delayShow={300} delayHide={150} overlay={renderTooltip(r[4])}>
                <tr key={r[0]}>{r.filter((c, cidx) => cidx !== 4).map((c, cidx) => <td key={cidx}>{cidx === 0 ? c : formatCurrencyByLocale(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, c)}</td>)}</tr>
              </OverlayTrigger>
            )
          }
        </tbody>
      </Table>
    );
  }
}

export default connect(mapStateToProps)(NetWorthTimeSeriesTable);
