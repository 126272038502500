import { SET_AGGREGATION_CURRENCY, SET_CURRENCY_RATES } from '../constants/actionTypes'
import { API, Auth } from 'aws-amplify'


export function initCurrencyInfo(aggregationCurrency) {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    const currencyRates  = await API.get("CurrencyRatesGateway", "/currency_rates", init);
    dispatch({ type: SET_CURRENCY_RATES, currencyRates })
  }
}

export const setAggregationCurrency = aggregationCurrency => ({
  type: SET_AGGREGATION_CURRENCY,
  aggregationCurrency
})

export const setCurrencyRates = currencyRates => ({
  type: SET_CURRENCY_RATES,
  currencyRates
})

