import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';


class AssetSummaryEmptyPlaceholder extends Component {
  render() {
    return (
      <Alert variant="secondary">
        <p>
          Interesting! You don't seem to have any assets with us yet. Try adding one using the <b>Create Asset</b> button.
        </p>
      </Alert>
    );
  }
}

export default AssetSummaryEmptyPlaceholder;
