import { combineReducers } from 'redux'
import assetInfo from './assets'
import currencyInfo from './currencies'
import liabilityInfo from './liabilities'
import netWorthInfo from './networth'

export default combineReducers({
  assetInfo,
  currencyInfo,
  liabilityInfo,
  netWorthInfo
})