const stackExports = require('../constants/renenetServiceExports.json');

const awsconfig = () => {
  const stackExportMap = stackExports.Exports.reduce( ( map, stackExport ) => {
      map[stackExport.Name] = stackExport.Value
      return map;
    },
    new Map()
  )

  return {
    Auth: {
      identityPoolId: stackExportMap['CognitoIdentityPoolId'],
      region: stackExportMap['CognitoRegion'],
      userPoolId: stackExportMap['CognitoUserPoolId'],
      userPoolWebClientId: stackExportMap['CognitoUserPoolAppClientId']
    },
    API: {
      endpoints: [
        {
          name: "AssetInfoGateway",
          endpoint: stackExportMap['AssetInfoGatewayEndpoint'].slice(0, -1)
        },
        {
          name: "AssetTypeGateway",
          endpoint: stackExportMap['AssetTypeGatewayEndpoint'].slice(0, -1)
        },
        {
          name: "CurrencyRatesGateway",
          endpoint: stackExportMap['CurrencyRatesEndpoint'].slice(0, -1)
        },
        {
          name: "LiabilityInfoGateway",
          endpoint: stackExportMap['LiabilityInfoGatewayEndpoint'].slice(0, -1)
        },
        {
          name: "LiabilityTypeGateway",
          endpoint: stackExportMap['LiabilityTypeGatewayEndpoint'].slice(0, -1)
        },
        {
          name: "NetWorthTimeseriesGatewayEndpoint",
          endpoint: stackExportMap['NetWorthTimeseriesGatewayEndpoint'].slice(0, -1)
        },
        {
          name: "SubscribersGateway",
          endpoint: stackExportMap['SubscribersGatewayEndpoint'].slice(0, -1)
        },
      ]
    },
    Storage: {
      AWSS3: {
        bucket: stackExportMap['BackendBucketName'],
        region: stackExportMap['BackendBucketRegion'],
        identityPoolId: stackExportMap['CognitoIdentityPoolId']
      }
    }
  }
}

export default awsconfig();