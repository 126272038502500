import React, { Component } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { initAssetSummary } from '../../../actions/assets'
import { Auth, API, Logger } from "aws-amplify";


const logger = new Logger('AssetCreateContainer');

function mapStateToProps (state) {
  return {
    assetInfo: state.assetInfo,
    currencyInfo: state.currencyInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initAssetSummary: currencyInfo => dispatch(initAssetSummary(currencyInfo))
  }
}

class AssetCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedType: undefined,
      name: '',
      selectedCurrency: undefined,
      initialBalance: 0,
      growthRate: 0,
      error: ''
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        'assetTypeName': this.state.selectedType,
        'assetName': this.state.name,
        'currency': this.state.selectedCurrency,
        'currentBalance': this.state.initialBalance,
        'growthRate': this.state.growthRate
      }
    };
    API.post("AssetInfoGateway", "/asset_info", init)
    .then(() => this.onSuccess(this.state.name))
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      selectedType: this.props.assetInfo.assetTypes[0],
      name: '',
      selectedCurrency: this.props.currencyInfo.aggregationCurrency,
      initialBalance: '0',
      growthRate: '0',
      error: ''
    })
  }

  onSuccess(asset) {
    logger.info('Asset Info created for ', asset);
    this.setState({ error: '', show: false });
    this.props.initAssetSummary(this.props.currencyInfo);
  }

  onFailure(err) {
    logger.info('Asset Info creation failed: ', err);
    this.setState({ error: err.message || err });
  }

  async componentDidMount() {
    this.setState({
      selectedType: this.props.assetInfo.assetTypes[0],
      selectedCurrency: this.props.currencyInfo.aggregationCurrency,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-grid gap-2">
          <Button variant="primary" onClick={() => this.setState({ show: true })}>
             Create Asset
          </Button>
        </div>

        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create Asset
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="typeList">
                  <Form.Label>Asset Type</Form.Label>
                  <Form.Control as="select" name="selectedType" value={this.state.selectedType} onChange={this.handleFormChange}>
                      {this.props.assetInfo.assetTypes.map(type => <option key={type}>{type}</option>)}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>Asset Name</Form.Label>
                  <Form.Control as="input" name="name" type="text" onChange={this.handleFormChange} placeholder="Enter asset name" />
                </Form.Group>
                <Form.Group controlId="currencyList">
                  <Form.Label>Asset Currency</Form.Label>
                  <Form.Control as="select" name="selectedCurrency" value={this.state.selectedCurrency} onChange={this.handleFormChange}>
                      {Object.keys(this.props.currencyInfo.currencyRates).map(currency => <option key={currency}>{currency}</option>)}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="initialBalance">
                  <Form.Label>Initial Balance</Form.Label>
                  <Form.Control as="input" name="initialBalance" type="number" onChange={this.handleFormChange} placeholder="Enter initial balance" />
                </Form.Group>
                <Form.Group controlId="growthRate">
                  <Form.Label>Growth Rate</Form.Label>
                  <Form.Control as="input" name="growthRate" type="number" onChange={this.handleFormChange} min="0" max="100" step="0.01" placeholder="Enter annual growth rate in %" />
                </Form.Group>
              </Form>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ show: false })}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={this.handleFormSubmit}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetCreateContainer);
