import React from "react";
import Alert from 'react-bootstrap/Alert';

export default function NotFound() {
  return (
    <Alert variant="danger">
      <Alert.Heading>Page In Alternate Dimension</Alert.Heading>
        <p>
          Wanna go back to your dimension? <Alert.Link href="/">Click here!</Alert.Link>
        </p>
      </Alert>
  );
}