import React from 'react';
import Container from 'react-bootstrap/Container';
import { Alert, Col, Row } from 'react-bootstrap';

import SubscribeContainer from './subscribe/SubscribeContainer.js';

const Home = (props) => (
  <Container>
    <Row>
      <Col>
        <h1 className="brand-text">moneyplant</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h5 className="text-muted">Personal Wealth Tracking - the private way!</h5>
      </Col>
    </Row>
    <Row>
      <Col>
        {
          props.user ? (
            <React.Fragment>
              <h4 className="text-muted">Hello { props.user.attributes.given_name }!</h4>
              <p className="text-muted">Welcome to your private wealth management portal. To get started, click on any of the tabs above.</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h6 className="text-muted">Update manually, whenever you want</h6>
              <h6 className="text-muted">No connected accounts means your financial account credentials remain private</h6>
              <h6 className="text-muted">Create custom assets and liabilities from pre-defined asset and liability types</h6>
              <h6 className="text-muted">Support for 100+ fiat currencies and 800+ cryptocurrencies</h6>
              <Alert variant='warning'>This is a limited beta launch. Only select emails can sign up. {''}<SubscribeContainer /> to be added to the waitlist.</Alert>
            </React.Fragment>
          )
        }
      </Col>
    </Row>
  </Container>
)

export default Home
