import React, { Component } from 'react';
import { Col, Form, Row }  from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateAssetTypeSummary } from '../actions/assets'
import { updateLiabilityTypeSummary } from '../actions/liabilities'
import { initCurrencyInfo, setAggregationCurrency } from '../actions/currencies'
import { initNetWorthSummary } from '../actions/networth'

function mapStateToProps (state) {
  return {
    assetSummary: state.assetInfo.assetSummary,
    liabilitySummary: state.liabilityInfo.liabilitySummary,
    currencyInfo: state.currencyInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initCurrencyInfo: aggregationCurrency => dispatch(initCurrencyInfo(aggregationCurrency)),
    setAggregationCurrency: aggregationCurrency => dispatch(setAggregationCurrency(aggregationCurrency)),
    updateAssetTypeSummary: (assetSummary, currencyInfo) => dispatch(updateAssetTypeSummary(assetSummary, currencyInfo)),
    updateLiabilityTypeSummary: (liabilitySummary, currencyInfo) => dispatch(updateLiabilityTypeSummary(liabilitySummary, currencyInfo)),
    initNetWorthSummary: (currencyInfo) => dispatch(initNetWorthSummary(currencyInfo))
  }
}

class AggregationCurrencySelector extends Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async handleSelectChange(event) {
      event.preventDefault();
      await this.props.setAggregationCurrency(event.target.value);
      await Promise.all([
        this.props.updateAssetTypeSummary(this.props.assetSummary, this.props.currencyInfo),
        this.props.updateLiabilityTypeSummary(this.props.liabilitySummary, this.props.currencyInfo),
        this.props.initNetWorthSummary(this.props.currencyInfo)
      ])
  }

  async componentDidMount() {
    await this.props.initCurrencyInfo(this.props.currencyInfo.aggregationCurrency)
  }

  render() {
    return (
      <Form>
        <Form.Group as={Row} controlId="currencyList">
          <Col></Col>
          <Form.Label column>Aggregation Currency</Form.Label>
          <Col>
            <Form.Select name="aggregationCurrency" value={this.props.currencyInfo.aggregationCurrency} onChange={this.handleSelectChange}>
              {Object.keys(this.props.currencyInfo.currencyRates).map(currency => <option key={currency}>{currency}</option>)}
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AggregationCurrencySelector);
