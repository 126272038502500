import React, { Component } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import { initAssetTypes, initAssetSummary } from '../../../actions/assets'

import AssetSummaryEmptyPlaceholder from './AssetSummaryEmptyPlaceholder.js'
import AssetSummaryTable from './AssetSummaryTable.js'
import AssetTotalHighlight from './AssetTotalHighlight.js'
import AssetTypeSummaryPie from './AssetTypeSummaryPie.js'
import AssetTypeSummaryTable from './AssetTypeSummaryTable.js'
import AssetCreateContainer from './AssetCreateContainer.js'


function mapStateToProps (state) {
  return {
    currencyInfo: state.currencyInfo,
    assetInfo: state.assetInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initAssetTypes: () => dispatch(initAssetTypes()),
    initAssetSummary: (currencyInfo) => dispatch(initAssetSummary(currencyInfo))
  }
}

class Asset extends Component {

  async componentDidMount() {
    await Promise.all([this.props.initAssetTypes(), this.props.initAssetSummary(this.props.currencyInfo)])
  }

  render() {
    return (
      <Container>
        <Row>
          <Col><h2>Asset Aggregation</h2></Col>
        </Row>
        <Row>
          <Col><AssetTotalHighlight /></Col>
        </Row>
        <Row>
          <Col>
            { this.props.assetInfo.assetTypeSummary === null ? <Spinner animation="border" variant="primary" /> : ( this.props.assetInfo.assetTypeSummary.length > 0 ? <AssetTypeSummaryTable /> : <AssetSummaryEmptyPlaceholder /> ) }
          </Col>
          <Col><AssetTypeSummaryPie /></Col>
        </Row>
        <Row>
          <Col><h2>Individual Assets</h2></Col>
          <Col />
          <Col>{this.props.assetInfo.assetTypes.length === 0 ? <Spinner animation="border" variant="primary" /> : <AssetCreateContainer /> }</Col>
        </Row>
        <Row>
          <Col>
            { this.props.assetInfo.assetSummary === null ? <Spinner animation="border" variant="primary" /> : ( this.props.assetInfo.assetSummary.length > 0 ? <AssetSummaryTable /> : <AssetSummaryEmptyPlaceholder /> ) }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
