import React from 'react'
import { Provider } from 'react-redux'
import store from './store'

import Amplify from 'aws-amplify';
import aws_exports from './config/aws-exports';

import './styles/styles.scss';
import "@aws-amplify/ui-react/styles.css";

import Header from './components/Header.js';
import RouteSwitch from './components/RouteSwitch.js';

Amplify.configure(aws_exports);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Header />
        <RouteSwitch />
      </Provider>
    </div>
  );
}

export default App;
