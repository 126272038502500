import { SET_CURRENT_NET_WORTH, SET_NET_WORTH_SUMMARY, SET_NET_WORTH_TIMEFRAME } from '../constants/actionTypes'
import { Timeframes } from '../constants/timeFrames'

const initialState = {
  currentNetWorth: 0,
  netWorthSummary: null,
  timeframe: Timeframes.ALL_TIME
}

const netWorthInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_NET_WORTH:
      return Object.assign({}, state, {
        currentNetWorth: action.currentNetWorth
      })
    case SET_NET_WORTH_SUMMARY:
      return Object.assign({}, state, {
        netWorthSummary: action.netWorthSummary
      })
    case SET_NET_WORTH_TIMEFRAME:
      return Object.assign({}, state, {
         timeframe: action.timeframe
      })
    default:
      return state
  }
}

export default netWorthInfo