const SHORT_FORMAT_OPTIONS = {
  notation: "compact",
  compactDisplay: "short"
}

export function formatCurrencyByLocale(locale, currencyCode, currencyValue) {
  return formatCurrencyByLocaleInternal(locale, currencyCode, currencyValue, false);
}

export function formatCurrencyByLocaleShort(locale, currencyCode, currencyValue) {
  return formatCurrencyByLocaleInternal(locale, currencyCode, currencyValue, true);
}

function formatCurrencyByLocaleInternal(locale, currencyCode, currencyValue, withShortFormat) {
  let currencyValueFormatted = null
  try {
    currencyValueFormatted = new Intl.NumberFormat(
      locale,
      getCurrencyOptions(currencyCode, withShortFormat)
    )
    .format(currencyValue);
  } catch (ex) {
    // format it based on just the local rounded to 2 decimal points
    currencyValueFormatted = new Intl.NumberFormat(
      locale,
      getDefaultOptions(withShortFormat)
    )
    .format(currencyValue) + " " + currencyCode;
  }
  return currencyValueFormatted;
}

function getCurrencyOptions(currencyCode, withShortFormat) {
  return {
    ...{
      style: 'currency',
      currency: currencyCode,
    },
    ...(withShortFormat ? SHORT_FORMAT_OPTIONS : {})
  };
}

function getDefaultOptions(withShortFormat) {
  return {
    ...{
      maximumSignificantDigits: 2
    },
    ...(withShortFormat ? SHORT_FORMAT_OPTIONS : {})
  };
}



