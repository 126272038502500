import { SET_AGGREGATION_CURRENCY, SET_CURRENCY_RATES } from '../constants/actionTypes'

const initialState = {
  aggregationCurrency: 'USD',
  currencyRates: {},
  locale: Intl.NumberFormat().resolvedOptions().locale
}

const currencyInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGGREGATION_CURRENCY:
      return Object.assign({}, state, {
        aggregationCurrency: action.aggregationCurrency
      })
    case SET_CURRENCY_RATES:
      return Object.assign({}, state, {
        currencyRates: action.currencyRates
      })
    default:
      return state
  }
}

export default currencyInfo