import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { initNetWorthSummary } from '../../../actions/networth';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';

import NetWorthSummaryEmptyPlaceholder from './NetWorthSummaryEmptyPlaceholder.js'
import NetWorthTimeSeriesUploadContainer from './NetWorthTimeSeriesUploadContainer.js'
import NetWorthTimeframeSelector from './NetWorthTimeframeSelector.js'
import NetWorthTimeSeriesLine from './NetWorthTimeSeriesLine.js'
import NetWorthTimeSeriesTable from './NetWorthTimeSeriesTable.js'


function mapStateToProps (state) {
  return {
    currencyInfo: state.currencyInfo,
    netWorthInfo: state.netWorthInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initNetWorthSummary: (currencyInfo) => dispatch(initNetWorthSummary(currencyInfo))
  }
}

class NetWorth extends Component {
  async componentDidMount() {
    await this.props.initNetWorthSummary(this.props.currencyInfo)
  }

  render() {
    return this.props.netWorthInfo.netWorthSummary === null
      ? (<Spinner animation="grow" variant="light" />)
      : (
        <Container>
          <Row>
            <Col><h2>Net Worth</h2></Col>
            <Col />
            <Col><NetWorthTimeSeriesUploadContainer /></Col>
          </Row>
          {
            this.props.netWorthInfo.netWorthSummary.length > 0
            ? (
              <React.Fragment>
                <Row>
                  <Col><h4>Your current net worth is:</h4></Col>
                </Row>
                <Row>
                  <Col><h2>{formatCurrencyByLocale(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, this.props.netWorthInfo.currentNetWorth)}</h2></Col>
                  <Col />
                  <Col><NetWorthTimeframeSelector /></Col>
                </Row>
                <Row>
                  <NetWorthTimeSeriesLine />
                </Row>
                <Row>
                  <NetWorthTimeSeriesTable />
                </Row>
              </React.Fragment>
            ) : (
              <Row>
                <Col><NetWorthSummaryEmptyPlaceholder /></Col>
              </Row>
            )
          }
        </Container>
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetWorth);
