import React, { Component } from 'react';
import { Table, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';

import AssetUpdateAction from './AssetUpdateAction.js';
import AssetDeleteAction from './AssetDeleteAction.js';

function mapStateToProps (state) {
  return {
    assetSummary: state.assetInfo.assetSummary,
    locale: state.currencyInfo.locale
  }
}

class AssetSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  render() {
    const renderTooltip = (text) => <Tooltip id="tooltip">Updated on {text}</Tooltip>;

    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {['Name', 'Type', 'Currency', 'Current Balance', 'Growth Rate (%)', 'EOY Balance', 'Actions'].map(c => <th key={c}>{c}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            this.props.assetSummary.map((r, ridx) =>
              <OverlayTrigger placement="top" delayShow={300} delayHide={150} overlay={renderTooltip(r[5])}>
                <tr key={ridx}>{
                  [
                    ...r.filter((c, cidx) => cidx !== 5).map((c, cidx) => <td key={cidx}>{[3, 5].includes(cidx) ? formatCurrencyByLocale(this.props.locale, r[2], c) : c}</td>),
                    <td key="actions">{
                      <React.Fragment>
                        <AssetUpdateAction iAssetSummaryRow={ridx} />
                        <AssetDeleteAction iAssetSummaryRow={ridx} />
                      </React.Fragment>
                    }</td>
                  ]
                }</tr>
              </OverlayTrigger>
            )
          }
        </tbody>
      </Table>
    );
  }
}

export default connect(mapStateToProps)(AssetSummaryTable);
