import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';


class NetWorthSummaryEmptyPlaceholder extends Component {
  render() {
    return (
      <Alert variant="secondary">
        <Alert.Heading>Hey, nice to see you!</Alert.Heading>
        <p>
          Looks like you are just getting started! Try adding some{' '}<Alert.Link as={Link} to="/asset">assets</Alert.Link> and{' '}
          <Alert.Link as={Link} to="/liability">liabilities</Alert.Link> to your name to have your net worth show up here.
        </p>
        <p>
          <b>Already ahead of the curve?</b> Click on the <b>Import Net-Worth Data</b> button above to
          import a file with your historical net-worth data.
        </p>
      </Alert>
    );
  }
}

export default NetWorthSummaryEmptyPlaceholder;
