import { SET_LIABILITY_SUMMARY, SET_LIABILITY_TYPES, SET_LIABILITY_TYPE_SUMMARY } from '../constants/actionTypes'
import { Auth, API } from "aws-amplify"
import moment from 'moment'

function computeEoyBalance(currentBalance, growthRate) {
    const msInADay = 1000 * 60 * 60 * 24
    const today = new Date();
    const eoy = new Date(new Date().getFullYear(), 11, 31);
    const nDaysLeftInYear = Math.ceil(Math.abs(eoy - today) / msInADay)
    const eoyBalance = currentBalance*(1 + (growthRate*nDaysLeftInYear)/(100*365))
    return eoyBalance
}

function toAggCurrency(value, originalCurrency, aggregationCurrency, currencyRates) {
    return parseFloat(value)* parseFloat(currencyRates[aggregationCurrency])/parseFloat(currencyRates[originalCurrency])
}

export function initLiabilitySummary(currencyInfo) {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    var liabilitySummary = await API.get("LiabilityInfoGateway", "/liability_info", init);

    liabilitySummary = liabilitySummary.sort(function(a,b) {
        if(a[1] < b[1]) { return -1; }
        if(a[1] > b[1]) { return 1; }

        if(a[0] < b[0]) { return -1; }
        if(a[0] > b[0]) { return 1; }

        return 0;
     });

    for (var iRow = 0; iRow < liabilitySummary.length; iRow++) {
      var balance = liabilitySummary[iRow][liabilitySummary[iRow].length - 3]
      var growthRate = liabilitySummary[iRow][liabilitySummary[iRow].length - 2]

      liabilitySummary[iRow].push(computeEoyBalance(balance, growthRate))
      // Update time format for last update string
      liabilitySummary[iRow][5] = moment(liabilitySummary[iRow][5]).format("MMM DD, YYYY hh:mm A UTC");
    }

    dispatch(setLiabilitySummary(liabilitySummary));
    dispatch(updateLiabilityTypeSummary(liabilitySummary, currencyInfo));
  }
}

export function initLiabilityTypes() {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    const response = await API.get("LiabilityTypeGateway", "/liability_types", init);
    const liabilityTypes = [...response.sort()]

    dispatch(setLiabilityTypes(liabilityTypes));
  }
}

export function updateLiabilityTypeSummary(liabilitySummary, currencyInfo) {
  return async dispatch => {
    let liabilityTypeSummaryMap = new Map();
    for (var iRow = 0; iRow < liabilitySummary.length; iRow++) {
      var type = liabilitySummary[iRow][1]
      var currency = liabilitySummary[iRow][2]
      var balance = liabilitySummary[iRow][liabilitySummary[iRow].length - 4]
      liabilityTypeSummaryMap.set(type, (
        (parseFloat(liabilityTypeSummaryMap.get(type)) || 0)
          + toAggCurrency(balance, currency, currencyInfo.aggregationCurrency, currencyInfo.currencyRates)
      ))
    }
    const liabilityTypeSummary = Array.from(liabilityTypeSummaryMap).sort(function(a,b) { return b[1] - a[1]; });
    dispatch(setLiabilityTypeSummary(liabilityTypeSummary))
  }
}

export const setLiabilitySummary = liabilitySummary => ({
  type: SET_LIABILITY_SUMMARY,
  liabilitySummary
})

export const setLiabilityTypes = liabilityTypes => ({
  type: SET_LIABILITY_TYPES,
  liabilityTypes
})

export const setLiabilityTypeSummary = liabilityTypeSummary => ({
  type: SET_LIABILITY_TYPE_SUMMARY,
  liabilityTypeSummary
})