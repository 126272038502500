import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';


function mapStateToProps (state) {
  return {
    liabilityTypeSummary: state.liabilityInfo.liabilityTypeSummary,
    currencyInfo : state.currencyInfo
  }
}

class LiabilityTotalHighlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  render() {
    let totalValue = this.props.liabilityTypeSummary.reduce( ( accumulator, r ) => accumulator + parseFloat(r[1]), 0 ).toFixed(2)
    return (
        <Alert variant='info'>
          {'Total Liabilities: ' + formatCurrencyByLocale(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, totalValue)}
        </Alert>
    );
  }
}

export default connect(mapStateToProps)(LiabilityTotalHighlight);
