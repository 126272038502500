import React, { Component } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import { initLiabilityTypes, initLiabilitySummary } from '../../../actions/liabilities'

import LiabilitySummaryEmptyPlaceholder from './LiabilitySummaryEmptyPlaceholder.js'
import LiabilitySummaryTable from './LiabilitySummaryTable.js'
import LiabilityTotalHighlight from './LiabilityTotalHighlight.js'
import LiabilityTypeSummaryPie from './LiabilityTypeSummaryPie.js'
import LiabilityTypeSummaryTable from './LiabilityTypeSummaryTable.js'
import LiabilityCreateContainer from './LiabilityCreateContainer.js'


function mapStateToProps (state) {
  return {
    currencyInfo: state.currencyInfo,
    liabilityInfo: state.liabilityInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initLiabilityTypes: () => dispatch(initLiabilityTypes()),
    initLiabilitySummary: (currencyInfo) => dispatch(initLiabilitySummary(currencyInfo))
  }
}

class Liability extends Component {

  async componentDidMount() {
    await Promise.all([this.props.initLiabilityTypes(), this.props.initLiabilitySummary(this.props.currencyInfo)])
  }

  render() {
    return (
      <Container>
        <Row>
          <Col><h2>Liability Aggregation</h2></Col>
        </Row>
        <Row>
          <Col><LiabilityTotalHighlight /></Col>
        </Row>
        <Row>
          <Col>
            { this.props.liabilityInfo.liabilityTypeSummary === null ? <Spinner animation="border" variant="primary" /> : ( this.props.liabilityInfo.liabilityTypeSummary.length > 0 ? <LiabilityTypeSummaryTable /> : <LiabilitySummaryEmptyPlaceholder /> ) }
          </Col>
          <Col><LiabilityTypeSummaryPie /></Col>
        </Row>
        <Row>
          <Col><h2>Individual Liabilities</h2></Col>
          <Col />
          <Col>{this.props.liabilityInfo.liabilityTypes.length === 0 ? <Spinner animation="border" variant="primary" /> : <LiabilityCreateContainer /> }</Col>
        </Row>
        <Row>
          <Col>
            { this.props.liabilityInfo.liabilitySummary === null ? <Spinner animation="border" variant="primary" /> : ( this.props.liabilityInfo.liabilitySummary.length > 0 ? <LiabilitySummaryTable /> : <LiabilitySummaryEmptyPlaceholder /> ) }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Liability);
