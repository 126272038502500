import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Auth, Logger, Storage } from "aws-amplify";
import { initNetWorthSummary } from '../../../actions/networth';


const logger = new Logger('NetWorthTimeSeriesUploadContainer');

function mapStateToProps (state) {
  return {
    currencyInfo: state.currencyInfo,
    netWorthInfo: state.netWorthInfo
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initNetWorthSummary: (currencyInfo) => dispatch(initNetWorthSummary(currencyInfo))
  }
}

class NetWorthTimeSeriesUploadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      importFile: null,
      error: '',
      isImporting: false
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormChange(event) {
    const inputValue = event.target.files[0];
    const stateField = event.target.id;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.importFile == null) {
      this.setState({ error: "No file chosen" });
      return;
    }
    this.setState({ isImporting: true })
    let userInfo = await Auth.currentUserInfo()
    // Append username as a prefix since there's no way to connect identity pool id with username, and
    // the lambda triggered needs cognito username for keying into the Timeseries DyanmoDB table
    Storage.put(`net-worth-uploads/${userInfo.username + '+' + this.state.importFile.name}`,
      this.state.importFile,
      { level: 'private', contentType: 'text/csv' }
    )
    .then(() => this.onSuccess(this.state.importFile))
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      importFile: '',
      error: '',
      isImporting: false
    })
  }

  onSuccess(file) {
    logger.info('Upload successful for ', file.name);
    setTimeout(() => {
      this.props.initNetWorthSummary(this.props.currencyInfo)
      this.setState({ error: '', show: false, importFile: null, isImporting: false })
    }, 3000)
  }

  onFailure(err) {
    logger.info('Upload failed: ', err);
    this.setState({ error: err.message || err, importFile: null, isImporting: false });
  }

  render() {
    return (
      <React.Fragment>
        <div className="d-grid gap-2">
          <Button variant="primary" onClick={() => this.setState({ show: true })}>
            Import Net-Worth Data
          </Button>
        </div>

        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Import Net-Worth Data
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="importFile">
                  <Form.Label>
                    <p>Choose a CSV file with 3 columns: YYYYMM date, asset worth in USD and liability worth in USD).</p>
                    <p>The file should not have any header rows.</p>
                    <p>For example, if you had 1234.56 USD assets and 78.9 USD liabilities in January 2020, then the corresponding row in the file should look like this:</p>
                    <p>202001,1234.56,78.9</p>
                  </Form.Label>
                  <Form.Control type="file" required accept=".csv, text/csv" onChange={this.handleFormChange} disabled={ this.state.isImporting } />
                </Form.Group>
              </Form>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
              { this.state.isImporting && <Spinner animation="border" variant="primary" /> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ show: false })} disabled={ this.state.isImporting }>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={this.handleFormSubmit} disabled={ this.state.isImporting }>
                    Import
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthTimeSeriesUploadContainer);
