import { SET_CURRENT_NET_WORTH, SET_NET_WORTH_SUMMARY, SET_NET_WORTH_TIMEFRAME } from '../constants/actionTypes'
import { Auth, API } from "aws-amplify"
import moment from 'moment'


function toAggCurrency(value, originalCurrency, aggregationCurrency, currencyRates) {
    return parseFloat(value)* parseFloat(currencyRates[aggregationCurrency])/parseFloat(currencyRates[originalCurrency])
}

export function initNetWorthSummary(currencyInfo) {
  return async dispatch => {
    const init = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    };
    const netWorthSummarySource = await API.get("NetWorthTimeseriesGatewayEndpoint", "/networth_info", init);

    const netWorthSummary = netWorthSummarySource.map(netWorthRow => {
      let assetInAggCurrency = toAggCurrency(netWorthRow[2], netWorthRow[1], currencyInfo.aggregationCurrency, currencyInfo.currencyRates);
      let liabilityInAggCurrency = toAggCurrency(netWorthRow[3], netWorthRow[1], currencyInfo.aggregationCurrency, currencyInfo.currencyRates);
      let dateKey = moment(netWorthRow[0], 'YYYYMM').locale('en').format('MMM YYYY');
      let lastUpdatedTime = moment(netWorthRow[4]).format("MMM DD, YYYY hh:mm A UTC");
      return [
        dateKey,
        assetInAggCurrency,
        liabilityInAggCurrency,
        (assetInAggCurrency - liabilityInAggCurrency),
        lastUpdatedTime
      ]
    })
    const netWorthSummaryLength = netWorthSummary.length;

    dispatch(setNetWorthSummary(netWorthSummary));
    dispatch(setCurrentNetWorth(netWorthSummary.length > 0 ? netWorthSummary[netWorthSummaryLength - 1][3] : 0 ));
  }
}

export const setCurrentNetWorth = currentNetWorth => ({
  type: SET_CURRENT_NET_WORTH,
  currentNetWorth
})

export const setNetWorthSummary = netWorthSummary => ({
  type: SET_NET_WORTH_SUMMARY,
  netWorthSummary
})

export const setNetWorthTimeframe = timeframe => ({
  type: SET_NET_WORTH_TIMEFRAME,
  timeframe
})