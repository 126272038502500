import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PieChart, Pie, Tooltip } from 'recharts';

import { DATA_COLORS } from '../../../constants/dataColors';

function mapStateToProps(state) {
  return { assetTypeSummary: state.assetInfo.assetTypeSummary };
}

class AssetTypeSummaryPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  render() {
    const totalValue = this.props.assetTypeSummary.reduce(
      (accumulator, r) => accumulator + parseFloat(r[1]),
      0
    );
    const data = this.props.assetTypeSummary.map((r, ridx) => ({
      name: r[0],
      value: parseFloat(r[1]),
      fill: DATA_COLORS[ridx],
    }));

    return (
      <PieChart width={600} height={400}>
        <Tooltip
          formatter={(value, name, { payload }) => {
            const percentage = (100 * payload.value / totalValue).toFixed(2);
            return [`${percentage} %`, `${payload.name}`];
          }}
        />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={200}
          dataKey="value"
        />
      </PieChart>
    );
  }
}

export default connect(mapStateToProps)(AssetTypeSummaryPie);
