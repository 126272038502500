import React, { Component } from 'react';
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux';
import { formatCurrencyByLocale } from '../../../utils/currencyUtils';


function mapStateToProps (state) {
  return {
    liabilityTypeSummary: state.liabilityInfo.liabilityTypeSummary,
    currencyInfo: state.currencyInfo
  }
}

class LiabilityTypeSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  render() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {['Type', 'Balance'].map(c => <th key={c}>{c}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            this.props.liabilityTypeSummary.map(r =>
              <tr key={r[0]}>{r.map((c, cidx) => <td key={c}>{cidx === 0 ? c : formatCurrencyByLocale(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, c)}</td>)}</tr>
            )
          }
        </tbody>
      </Table>
    );
  }
}

export default connect(mapStateToProps)(LiabilityTypeSummaryTable);
