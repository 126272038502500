import React, { Component } from 'react';
import { Form }  from 'react-bootstrap';
import { connect } from 'react-redux';
import { initNetWorthSummary, setNetWorthTimeframe } from '../../../actions/networth'
import { Timeframes } from '../../../constants/timeFrames'

function mapStateToProps (state) {
  return {
    timeframe: state.netWorthInfo.timeframe
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setNetWorthTimeframe: timeframe => dispatch(setNetWorthTimeframe(timeframe)),
    initNetWorthSummary: (currencyInfo) => dispatch(initNetWorthSummary(currencyInfo))
  }
}

class NetWorthTimeframeSelector extends Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async handleSelectChange(event) {
      event.preventDefault();
      await this.props.setNetWorthTimeframe(this.toSnakeCase(event.target.value));
  }

  toSentenceCase(str) {
    return str
      .toString()
      .split("_")
      .map((word) => (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
      .join(" ");
  }

  toSnakeCase(str) {
      return Timeframes[str
        .split(" ")
        .map((word) => (word.toUpperCase()))
        .join("_")];
    }

  render() {
    return (
      <Form>
        <Form.Group controlId="timeframeList">
          <Form.Select name="timeframeSelector" value={this.toSentenceCase(this.props.timeframe)} onChange={this.handleSelectChange}>
            {Object.keys(Timeframes).map(timeframe => <option key={timeframe}>{this.toSentenceCase(timeframe)}</option>)}
          </Form.Select>
        </Form.Group>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthTimeframeSelector);
