import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { Container, Nav, Navbar } from "react-bootstrap";
import { Auth, Hub, Logger } from 'aws-amplify';

import SignInContainer from './authenticate/SignInContainer.js'
import LogoutContainer from './authenticate/LogoutContainer.js'
import AggregationCurrencySelector from './AggregationCurrencySelector.js'

const mapStateToProps = state => {
  return { session: state.session }
}

const logger = new Logger('Header');

class Header extends Component {
  constructor(props) {
    super(props);
    this.loadUser = this.loadUser.bind(this);
    Hub.listen('auth', (event) => this.triggerOnHubEvent(event) , 'header');
    this.state = { user: null }
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ user: user }))
      .catch(err => this.setState({ user: null }));
  }

  triggerOnHubEvent(event) {
    logger.info('Detected Auth event:', event);
    this.loadUser();
  }

  render() {
    return (
      <Navbar bg="light" variant="light" collapseOnSelect expand="md" className="mb-3">
          <Navbar.Brand as={Link} to="/">
            <img alt="" src="/favicon.ico" width="30px" height="30px" className="d-inline-block align-top"/>{' '}<span className="brand-text">moneyplant</span>
          </Navbar.Brand>
          <Navbar.Toggle />
          {
            this.state.user ? (
              <Navbar.Collapse>
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/networth">NetWorth</Nav.Link>
                  <Nav.Link as={Link} to="/asset">Assets</Nav.Link>
                  <Nav.Link as={Link} to="/liability">Liabilities</Nav.Link>
                </Nav>
                <Container className="justify-content-end">
                  <AggregationCurrencySelector />
                </Container>
                <LogoutContainer />
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse className="justify-content-end">
                <Nav>
                  <SignInContainer />
                </Nav>
              </Navbar.Collapse>
            )
          }
      </Navbar>
    );
  }
}

export default connect(mapStateToProps)(Header)