import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import moment from 'moment';
import { Timeframes } from '../../../constants/timeFrames';
import { formatCurrencyByLocaleShort } from '../../../utils/currencyUtils';

import { DATA_COLORS } from '../../../constants/dataColors';


function mapStateToProps (state) {
  return {
    currencyInfo: state.currencyInfo,
    netWorthSummary: state.netWorthInfo.netWorthSummary,
    timeframe: state.netWorthInfo.timeframe
  }
}

class NetWorthTimeSeriesLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  isRowInTimeframe(row) {
    let rowMoment = moment(row[0], 'MMM YYYY');
    switch (this.props.timeframe) {
      case Timeframes.ALL_TIME:
        return true;
      case Timeframes.LAST_10_YEARS:
        return moment().subtract(10, 'years').isBefore(rowMoment);
      case Timeframes.LAST_5_YEARS:
        return moment().subtract(5, 'years').isBefore(rowMoment);
      case Timeframes.LAST_2_YEARS:
        return moment().subtract(2, 'years').isBefore(rowMoment);
      case Timeframes.LAST_1_YEAR:
        return moment().subtract(1, 'years').isBefore(rowMoment);
      case Timeframes.YEAR_TO_DATE:
        return moment().startOf('year').isBefore(rowMoment);
      default:
        return false;
    }
  }

  render() {
    const data = this.props.netWorthSummary
      .filter(r => this.isRowInTimeframe(r))
      .map(r => { return {"Month": r[0], "Net Worth": parseFloat(r[3])} })
    return (
    	<LineChart width={1250} height={500} data={data} >
    	  <XAxis dataKey="Month"/>
    	  <YAxis dx={2} tickFormatter={(value) => formatCurrencyByLocaleShort(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, value)} />
    	  <CartesianGrid strokeDasharray="3 3"/>
    	  <Tooltip formatter={(value) => formatCurrencyByLocaleShort(this.props.currencyInfo.locale, this.props.currencyInfo.aggregationCurrency, value)}/>
    	  <Legend />
          <Line type="monotone" dataKey="Net Worth" stroke={DATA_COLORS[5]} activeDot={{r: 8}}/>
        </LineChart>
    );
  }
}

export default connect(mapStateToProps)(NetWorthTimeSeriesLine);
