import React, { Component } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Auth, Hub, Logger } from 'aws-amplify';

import NotFound from './notFound/NotFound.js'
import Home from './Home'
import NetWorth from './dashboards/networth/NetWorth.js'
import Asset from './dashboards/asset/Asset.js'
import Liability from './dashboards/liability/Liability.js'

const logger = new Logger('RouteSwitch');

class RouteSwitch extends Component {
  constructor(props) {
    super(props);
    this.loadUser = this.loadUser.bind(this);
    Hub.listen('auth', (event) => this.triggerOnHubEvent(event) , 'route-switch');
    this.state = { user: null }
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ user: user }))
      .catch(err => this.setState({ user: null }));
  }

  triggerOnHubEvent(event) {
    logger.info('Detected Auth event:', event);
    this.loadUser();
  }

  isAuthenticated() {
    return !!this.state.user;
  }

  render() {
    return (
      <main>
        <Routes>
          <Route path='/' element={<Home user={this.state.user} />} />
          <Route path='/networth' element={(
            this.isAuthenticated()
            ? <NetWorth user={this.state.user} />
            : <Navigate replace to="/" />
          )} />
          <Route path='/asset' element={(
            this.isAuthenticated()
            ? <Asset user={this.state.user} />
            : <Navigate replace to="/" />
          )} />
          <Route path='/liability' element={(
            this.isAuthenticated()
            ? <Liability user={this.state.user} />
            : <Navigate replace to="/" />
          )} />
          <Route element={<NotFound />} />
        </Routes>
      </main>
    )
  }
}

export default RouteSwitch
