import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';


class LiabilitySummaryEmptyPlaceholder extends Component {
  render() {
    return (
      <Alert variant="secondary">
        <p>
          Interesting! You don't seem to have any liabilities with us yet. Try adding one using the <b>Create Liability</b> button.
        </p>
      </Alert>
    );
  }
}

export default LiabilitySummaryEmptyPlaceholder;
