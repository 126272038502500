export const SET_AGGREGATION_CURRENCY = 'SET_AGGREGATION_CURRENCY'
export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES'

export const SET_ASSET_TYPES = 'SET_ASSET_TYPES'
export const SET_ASSET_TYPE_SUMMARY = 'SET_ASSET_TYPE_SUMMARY'
export const SET_ASSET_SUMMARY = 'SET_ASSET_SUMMARY'

export const SET_LIABILITY_TYPES = 'SET_LIABILITY_TYPES'
export const SET_LIABILITY_TYPE_SUMMARY = 'SET_LIABILITY_TYPE_SUMMARY'
export const SET_LIABILITY_SUMMARY = 'SET_LIABILITY_SUMMARY'

export const SET_CURRENT_NET_WORTH = 'SET_CURRENT_NET_WORTH'
export const SET_NET_WORTH_SUMMARY = 'SET_NET_WORTH_SUMMARY'
export const SET_NET_WORTH_TIMEFRAME = 'SET_NET_WORTH_TIMEFRAME'


