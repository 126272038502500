import { SET_ASSET_SUMMARY, SET_ASSET_TYPES, SET_ASSET_TYPE_SUMMARY } from '../constants/actionTypes'

const initialState = {
  assetSummary: [],
  assetTypes: [],
  assetTypeSummary: []
}

const assetInfo = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSET_SUMMARY:
      return Object.assign({}, state, {
        assetSummary: action.assetSummary
      })
    case SET_ASSET_TYPES:
      return Object.assign({}, state, {
        assetTypes: action.assetTypes
      })
    case SET_ASSET_TYPE_SUMMARY:
      return Object.assign({}, state, {
        assetTypeSummary: action.assetTypeSummary
      })
    default:
      return state
  }
}

export default assetInfo