import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { Auth } from 'aws-amplify';

class LogoutContainer extends Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(event) {
      event.preventDefault();
      Auth.signOut();
  }

  render() {
    return (
      <Button variant="secondary" onClick={this.handleButtonClick}>
         Logout
      </Button>
    );
  }
}

export default LogoutContainer
