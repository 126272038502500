import React, { Component } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { API, Logger } from "aws-amplify";


const logger = new Logger('SubscribeContainer');

class SubscribeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      emailId: '',
      givenName: '',
      familyName: '',
      error: ''
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    const init = {
      body: {
        'emailId': this.state.emailId,
        'givenName': this.state.givenName,
        'familyName': this.state.familyName
      }
    };
    API.post("SubscribersGateway", "/subscriber", init)
    .then(() => this.onSuccess())
    .catch(err => this.onFailure(err));
  }

  handleModalClose() {
    this.setState({
      show: false,
      emailId: '',
      givenName: '',
      familyName: '',
      error: ''
    })
  }

  onSuccess(asset) {
    logger.info('Subscribed successfully');
    this.setState({ error: '', show: false });
  }

  onFailure(err) {
    logger.info('Subscribing failed: ', err);
    this.setState({ error: err.message || err });
  }

  render() {
    return (
      <React.Fragment>
        <Alert.Link onClick={() => this.setState({ show: true })}>
          Subscribe
        </Alert.Link>

        <Modal show={this.state.show} onHide={() => this.handleModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Subscribe
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="emailId">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" name="emailId" value={this.state.emailId} onChange={this.handleFormChange} placeholder="Enter email" />
                </Form.Group>
                <Form.Group controlId="givenName">
                  <Form.Label>Given Name</Form.Label>
                  <Form.Control as="input" type="text" name="givenName" value={this.state.givenName} onChange={this.handleFormChange} placeholder="Enter first/given name" />
                </Form.Group>
                <Form.Group controlId="familyName">
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control as="input" type="text" name="familyName" value={this.state.familyName} onChange={this.handleFormChange} placeholder="Enter last/family name" />
                </Form.Group>
              </Form>
              { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleModalClose()}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={this.handleFormSubmit}>
                    Subscribe
                </Button>
            </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SubscribeContainer;
