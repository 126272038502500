import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';

class SignInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    return (
      <React.Fragment>
        <Button variant="primary" onClick={() => this.setState({ show: true })}>
           Sign In
        </Button>
        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
          <ThemeProvider>
            <Authenticator
              loginMechanisms={["email"]}
              signUpAttributes={["given_name", "family_name"]}>
              {({ signOut, user }) => (
                <main>
                  <h1>Hello {user.username}</h1>
                    <Button onClick={signOut}>Sign out</Button>
                  </main>
                )
              }
            </Authenticator>
          </ThemeProvider>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SignInContainer
